/*
 * @Autor: syq
 * @Date: 2021-08-18 16:31:33
 */
import { trackEvent } from './utils'


// 查询类型
const swipeType = {
  4: "brandId",
  2: "classId",
  1: "itemCode",
  3: "code",
  5:'activityNo',
  6:'frontCategoryId'
};
const linkTypeToparams = {
  brandId: "id",
  classId: "id",
  itemCode: "itemCode",
  code: "storeCode",
  activityNo:'activityNo',
};
// 数据类型
/*
 * swipe{
 *    linkType:1
 *    linkContent:[{}]
 * }
 *
 * list{
 *   linkType:1
 *   rule{itemsRule:{itemListRuleContent:[]}}
 * }
 */
const toPage = {
  swipe: function (router, item, linkType, storeCode) {
    // 店铺
    if (item.linkType === 3) {
      router.push({
        path: "/storehome",
        query: {
          storeCode: item?.linkContent.map(i => i.code)?.toString(),
          storeid: item?.linkContent.map(i =>i.id)?.toString(),
        },
      });
    } else{
      router.push({
        path: "/theme",
        query: {
          [swipeType[linkType]]:  linkType !== 6 ? item?.linkContent
            .map((i) => {
              return i[linkTypeToparams[[swipeType[linkType]]]];
            })?.toString() : item?.linkContent.map((i) => {
              return i;
            })?.toString(),
            storeCode: storeCode
        },
      });
    }
  },
  list: function (router, item, linkType, storeCode, activityNolist) {
    if(linkType === 5){
       router.push({
        path: "/theme",
        query: {
          [swipeType[linkType]]: activityNolist.rule.itemsRule.ruleDetail.map((i)=>{
              return i.activityNo
            }).toString(),
          storeCode: storeCode
        },
      });
    }else{
      router.push({
        path: "/theme",
        query: {
          [swipeType[linkType]]: item.items
            .map((i) => i[linkTypeToparams[[swipeType[linkType]]]])
            .toString(),
          storeCode: storeCode
        },
      });
    }
  },
};
const toTheme = function (router, item, type, linkType, storeCode,activityNolist) {
  if (linkType == 7) return;
  trackEvent(null, type === 'swipe' ? '点击banner页' : '点击查看更多')
  return toPage[type](router, item, linkType, storeCode,activityNolist);
};

const yvlantoPage = {
  swipe: function (router, item, linkType, storeCode) {
    // 店铺
    if (item.linkType === 3) {
      router.push({
        path: "/yvlanstorehome",
        query: {
          storeCode: item?.linkContent.map(i => i.code)?.toString(),
          storeid: item?.linkContent.map(i =>i.id)?.toString(),
        },
      });
    } else {
      router.push({
        path: "/yvlantheme",
        query: {
          [swipeType[linkType]]: item?.linkContent
            .map((i) => {
              return i[linkTypeToparams[[swipeType[linkType]]]];
            })
            ?.toString(),
            storeCode: storeCode
        },
      });
    }
  },
  list: function (router, item, linkType, storeCode, activityNolist) {
    if(linkType === 5){
       router.push({
        path: "/yvlantheme",
        query: {
          [swipeType[linkType]]: activityNolist.rule.itemsRule.ruleDetail.map((i)=>{
              return i.activityNo
            }).toString(),
          storeCode: storeCode
        },
      });
    }else{
      router.push({
        path: "/yvlantheme",
        query: {
          [swipeType[linkType]]: item.items
            .map((i) => i[linkTypeToparams[[swipeType[linkType]]]])
            .toString(),
          storeCode: storeCode
        },
      });
    }
  },
};
const yvlantoTheme = function (router, item, type, linkType, storeCode,activityNolist) {
  trackEvent(null, type === 'swipe' ? '点击banner页' : '点击查看更多')
  return yvlantoPage[type](router, item, linkType, storeCode,activityNolist);
};
export { toTheme, swipeType ,yvlantoTheme};
