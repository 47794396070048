<!--
 * @Autor: syq
 * @Date: 2021-07-15 14:09:55
-->
<template>
  <div class="twoMoreLines">
    <div class="twoMoreLines-header">
      <div class="twoMoreLines-header-title">{{ title }}</div>
      <a class="twoMoreLines-header-btn" @click="searchMore(items)"
        >查看更多></a
      >
    </div>
    <div class="fourInarow-details">
      <van-grid :column-num="4" :border="false">
        <van-grid-item
          v-for="i in items.rule.itemsRule.ruleDetail"
          :key="i.id"
          @click="() => shopdetails(i)"
        >
          <van-image class="van-img" :src="findUrl(i)" />
          <p class="name">{{ i.name }}</p>
          <p class="price">￥{{ i.price }}</p>
        </van-grid-item>
      </van-grid>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    items: Object,
  },
  methods: {
    findUrl(item) {
      return item?.mediaList ? item.mediaList[0].url : undefined;
    },
    shopdetails(item) {
      const { itemCode, storeCode } = item;
      this.$router.push({
        path: "/shopdetails",
        query: { itemCode, storeCode },
      });
    },
    searchMore(item) {
      if (item.linkType === 1)
        this.$router.push({
          path: "/theme",
          query: { spuid: item.rule.itemsRule.itemListRuleContent },
        });
      // if (item.linkType === 2)
      //   this.$router.push({
      //     path: "/theme",
      //     query: { linkType: item.linkType },
      //   });
      // if (item.linkType === 3)
      //   this.$router.push({
      //     path: "/theme",
      //     query: { linkType: item.linkType },
      //   });
      // if (item.linkType === 3)
      //   this.$router.push({
      //     path: "/theme",
      //     query: { linkType: item.linkType },
      //   });
    },
  },
};
</script>

<style lang="less" scoped>
.twoMoreLines {
  width: 100%;
  padding: 0 6px;
  margin-top: 8px;
  .twoMoreLines-header {
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    .twoMoreLines-header-title {
      padding-left: 8px;
      font-size: 16px;
      color: @Titlecolor;
      font-weight: 600;
    }
    .twoMoreLines-header-btn {
      padding-right: 8px;
      font-size: 14px;
      color: #ccc;
    }
  }
  .fourInarow-details {
    width: 100%;
    /deep/ .van-grid-item__content {
      padding: 8px;
      .van-img {
        width: 158px;
        height: 158px;
      }
      .name {
        margin: 4px 0 0 0;
        width: 158px;
        height: 16px;
        line-height: 16px;
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .price {
        margin: 4px 0 12px 0;
        width: 158px;
        color: @Price;
        height: 16px;
        line-height: 16px;
        font-size: 14px;
      }
    }
  }

  // /deep/.van-cell {
  //   font-size: 18px;
  //   color: #000;
  //   font-weight: 600;
  // }
}
</style>
