<!--
 * @Autor: syq
 * @Date: 2021-07-09 19:26:22
-->
<template>
  <div id="home">
    <!-- 搜索 -->
    <div class="search">
      <van-button
        round
        size="small"
        block
        :icon="sousuo"
        type="default"
        :to="{ path: '/searchbox', query: { type: 'defaultSearch' } }"
      >
        <span @click="trackEventInner">请输入关键词进行搜索</span>
      </van-button>
      <div class="search-title" @click="msg">
        <van-badge dot>
          <img class="imgStyle" src="../../assets/icon/message.svg" />
        </van-badge>
      </div>
    </div>

    <!-- 9/25开发新组件 -->
    <!-- 一行一图 -->
    <!-- <RowImg></RowImg> -->
    <!-- 一行四个 -->
    <!-- <FourRow></FourRow> -->
    <!--首页模块  -->
    <div class="home-content">
      <div v-for="(item, index) in home" :key="index">
        <!-- 轮播图 -->
        <div class="swipe" v-if="item.type === 'yihangyitu'">
          <van-swipe :autoplay="3000" width="100%" height="100%">
            <van-swipe-item
              v-for="(image, index) in JSON.parse(item.content).mediaContent"
              :key="index"
            >
              <img
                class="swipeimg"
                v-lazy="findImage(image)"
                @click="toTheme($router, image, 'swipe', image.linkType)"
              />
            </van-swipe-item>
          </van-swipe>
        </div>
        <!-- 动态渲染模块 -->
        <div v-if="item.type === 'promotionArea'&&Object.keys(findItems(item.key)).length">
          <mian-img
            :imgurl="JSON.parse(item.content).leftImg[0].url"
            :title="JSON.parse(item.content).name"
            :items="findItems(item.key)"
            :linkType="JSON.parse(item.content).linkType"
            :activityNolist="JSON.parse(item.content)"
          />
          <!-- <four-in-a-row
            :title="JSON.parse(item.content).name"
            :items="findItems(item.key)"
            :linkType="JSON.parse(item.content).linkType"
          /> -->
        </div>
        <div
          v-if="
            item.type === 'biaotibanner' &&
            Object.keys(findItems(item.key)).length
          "
        >
          <four-in-a-row
            :title="JSON.parse(item.content).name"
            :items="findItems(item.key)"
            :linkType="JSON.parse(item.content).linkType"
          />
        </div>
        <div
          v-if="
            item.type === 'productList' &&
            Object.keys(findItems(item.key)).length
          "
        >
          <four-in-a-list
            :title="JSON.parse(item.content).name"
            :items="findItems(item.key)"
            :linkType="JSON.parse(item.content).linkType"
          />
          <!-- <two-more-lines
          :title="JSON.parse(item.content).name"
          :items="JSON.parse(item.content)"
        /> -->
        </div>
        <!-- 广告图 -->
        <div v-if="item.type === 'aLineOfMany' && Object.keys(findItems(item.key)).length">
          <advertising-map :data="JSON.parse(item.content)"/>
        </div>
        <!-- 4个图标
      <div class="home-menu">
        <van-grid :border="false" :column-num="4">
          <van-grid-item
            v-for="(item, index) in channel"
            :key="index"
            :icon="item.icon_url"
            :text="item.name"
            @click="searchMore"
          />
        </van-grid>
      </div> -->

        <!-- 单图 -->
        <!-- <div class="alone">
        <img
          src="http://www.liby.com.cn/upload/cms/www/201710/16095327baxg.jpg"
          alt=""
        />
      </div> -->
      </div>
    </div>
    <!-- 两行多个 -->
    <!-- <two-more-lines :title="biaotibanner.name" :items="ruleDetail" /> -->
    <van-loading size="24px" v-show="homemsg" vertical>加载中...</van-loading>
  </div>
</template>

<script>
import icon0 from '../../assets/icon/home1.svg'
import icon1 from '../../assets/icon/home2.svg'
import icon2 from '../../assets/icon/home3.svg'
import icon3 from '../../assets/icon/home4.svg'
import sousuo from '../../assets/icon/souuso_icon@2x.png'
import FourInARow from './components/FourInARow.vue'
import FourInAList from './components/FourInAList.vue'
import TwoMoreLines from './components/TwoMoreLines.vue'
import RowImg from './components/RowImg.vue'
import FourRow from './components/FourRow.vue'
import MianImg from './components/mianImg.vue';
import AdvertisingMap from './components/AdvertisingMap.vue'
import { homemeeage, queryTotalItemList, isdefine } from './service'
import { toTheme, swipeType } from '../../utils/homeToTheme'
import { trackEvent } from '../../utils/utils'
import { Toast } from 'vant'
const paramsType = {
  brandId: 'brandId',
  classId: 'classId',
  code: 'code',
  itemCode: 'itemCode',
  activityNo: 'activityNo',
}
export default {
  components: { FourInARow, TwoMoreLines, FourInAList, RowImg, FourRow,MianImg ,AdvertisingMap},
  data() {
    return {
      toTheme,
      paramsType,
      swipeType,
      sousuo,
      searchData: '',
      moudleType: ['biaotibanner', 'productList','promotionArea','aLineOfMany'],
      channel: [
        {
          icon_url: icon0,
          name: '厂货通',
        },
        {
          icon_url: icon1,
          name: '品牌优选',
        },
        {
          icon_url: icon2,
          name: '百亿补贴',
        },
        {
          icon_url: icon3,
          name: '企业汇彩',
        },
      ],
      goods: [],
      home: [],
      queryDataByParams: {},
      homemsg: false,
    }
  },

  methods: {
    trackEventInner(eventName) {
      trackEvent(null, '点击搜索栏')
    },
    async homemessage() {
      this.homemsg = true
      const res = await homemeeage()
      if (res.status === '0') {
        this.home = JSON.parse(res?.data?.configuration || "[]")
        // console.log(this.home,"list");
        if (res?.data?.configuration) {
          JSON.parse(res.data.configuration).forEach(async (item, index) => {
            if (this.moudleType.includes(item.type)) {
              const itemContent = JSON.parse(item.content)
              const params = {
                currentPage: 1,
                [this.paramsType[this.swipeType[itemContent.linkType]]]:
                  itemContent?.rule?.itemsRule?.itemListRuleContent?.toString(),
              }
              const res = await queryTotalItemList(params)
              if (res.status === '0') {
                this.$set(this.queryDataByParams, item.key, {
                  items: res.data.items,
                  rowNum: itemContent?.rowNum
                    ? Number(itemContent.rowNum)
                    : undefined,
                })
              } else {
                Toast(res.msg)
              }
            }
          })
        }
        this.homemsg = false
      } else {
        this.homemsg = false
        Toast.fail(res.msg)
      }
    },

    findItems(key) {
      return this.queryDataByParams[key] ? this.queryDataByParams[key] : {}
    },

    findImage(item) {
      return item?.mediaContent[0].url
        ? item.mediaContent[0].url
        : 'http://uat-oss-business-middle-platform.oss-cn-shenzhen.aliyuncs.com/202108202047596941428700340054843393.png?Expires=1944823679&OSSAccessKeyId=LTAI5tMBSdRFFsKwy54k3F3J&Signature=c%2B%2FQNCjX6kK7mi1wrIZlpdJjCMg%3D'
    },

    msg() {
      this.$router.push('/message')
    },

    searchMore() {
      this.$router.push('/theme')
    },
  },

  created() {
    this.homemessage()
    isdefine()
      .then((res) => {
        localStorage.setItem('ispremission', res.data)
      })
      .catch((err) => {
        return err
      })
  },
}
</script>

<style lang="less" scoped>
#home {
  width: 100%;
  height: 100%;
  padding-bottom: 8px;
  display: flex;
  flex-direction: column;
  background-color: @BackgroundColor;
  /deep/ .search {
    display: flex;
    align-items: center;
    height: 54px;
    padding: 5px 12px;
    background-image: @GradientColor;
    .van-button__text {
      color: #96979b;
    }
    .van-button--small {
      height: 32px;
      border: none;
    }
    .search-title {
      width: 32px;

      border: none;
      height: 44px;
      padding: 8px 0 8px 8px;
      .van-badge__wrapper {
        padding-top: 2px;
        .van-badge--dot {
          border-color: #ee0a24;
        }
        .van-badge--fixed {
          top: 3px;
          right: 6px;
        }
        .imgStyle {
          width: 24px;
          height: 24px;
          color: #fff;
        }
      }
    }
  }
  .home-content {
    flex: 1 1 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    .swipe {
      width: 100%;
      height: 138px;
      .van-swipe {
        width: 100%;
        height: 100%;
        .swipeimg {
          width: 100%;
          height: 100%;
        }
      }
    }
    .home-menu {
      width: 100%;
      height: 106px;
      padding: 0 12px;
      margin: 9px 0;
      .van-grid {
        width: 100%;
        height: 100%;
        /deep/.van-grid-item__content {
          padding: 8px;
          .van-grid-item__icon {
            font-size: 50px;
          }
          .van-grid-item__text {
            font-size: 14px;
          }
        }
      }
    }
    .alone {
      width: 100%;
      height: 96px;
      padding: 0 12px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
