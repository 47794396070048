<!--
 * @Autor: syq
 * @Date: 2021-07-15 14:08:57
-->

<template>
  <div id="FourRow">
    <div class="fourRowWarp">
      <div class="fourRowCenter" v-for="item in list" :key="item.title">
        <p><img :src="item.img" /></p>
        <span>{{ item.title }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      list: [
        {
          title: '超威',
          img: 'http://bitsun-trs.oss-cn-shanghai.aliyuncs.com/202109081536122221435507244673273858.png?Expires=1946446572&OSSAccessKeyId=LTAI4GDpqhJcrCK7B39erovg&Signature=EF66D1Uaxg2U4WVXMxFWpJqkk1A%3D',
        },
        {
          title: '蓝天',
          img: 'http://bitsun-trs.oss-cn-shanghai.aliyuncs.com/202109081536122221435507244673273858.png?Expires=1946446572&OSSAccessKeyId=LTAI4GDpqhJcrCK7B39erovg&Signature=EF66D1Uaxg2U4WVXMxFWpJqkk1A%3D',
        },
        {
          title: '六必治',
          img: 'http://bitsun-trs.oss-cn-shanghai.aliyuncs.com/202109081536122221435507244673273858.png?Expires=1946446572&OSSAccessKeyId=LTAI4GDpqhJcrCK7B39erovg&Signature=EF66D1Uaxg2U4WVXMxFWpJqkk1A%3D',
        },
        {
          title: '呵呵宝贝',
          img: 'http://bitsun-trs.oss-cn-shanghai.aliyuncs.com/202109081536122221435507244673273858.png?Expires=1946446572&OSSAccessKeyId=LTAI4GDpqhJcrCK7B39erovg&Signature=EF66D1Uaxg2U4WVXMxFWpJqkk1A%3D',
        },
      ],
    }
  },
  methods: {},
}
</script>
<style lang="less" scoped>
#FourRow {
  width: 100%;
  overflow: hidden;
  margin: 8px 0;
  padding: 16px 0;
  background: #fff;
  height: 106px;

  .fourRowWarp {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }

  p {
    width: 50px;
    height: 50px;
    opacity: 1;
    background: #f6bc33;
    border-radius: 8px;
    margin: 0;
    margin-bottom: 4px;
  }

  span {
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    color: #4d4d4d;
    line-height: 20px;
  }

  img {
    width: 100%;
    height: 100%;
  }

  .fourRowCenter {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
</style>
