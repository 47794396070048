<!--
 * @Autor: syq
 * @Date: 2021-07-15 14:08:57
-->

<template>
  <div id="RowImg">
    <img
      src="http://bitsun-trs.oss-cn-shanghai.aliyuncs.com/202109081536122221435507244673273858.png?Expires=1946446572&OSSAccessKeyId=LTAI4GDpqhJcrCK7B39erovg&Signature=EF66D1Uaxg2U4WVXMxFWpJqkk1A%3D"
    />
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {}
  },
  methods: {},
}
</script>
<style lang="less" scoped>
#RowImg {
  width: 100%;
  overflow: hidden;
  margin: 8px 0;
  padding: 0 4px;
  height: 93px;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
