/*
 * @Autor: syq
 * @Date: 2021-07-12 16:37:32
 */

import request from "../../request/request";
import {stringify} from 'qs'
// 获取商品列表
const getItemList = async () =>
  request({
    url: `/item/getItemList`,
    method: "get",
});
const homemeeage = async () =>
  request({
    url: `/construct/getOneConstruct?qp-status-eq=1&qp-type-eq=3`,
    method: "get",
  });

// currentPage, brandId, classid, spuid
const queryTotalItemList = async (params) => {
  return request({
    url:`/item/getItemList?${stringify(params)}`,
    method: "get",
  })
};

//获取路由权限判断
const isdefine = async () =>
  request({
    url: `/channel/getAccountChannel`,
    method: "get",
  });

export { getItemList, homemeeage, queryTotalItemList, isdefine};
